import React from 'react'
import Layout from "./../components/Layout";
import { Box,  Typography } from "@mui/material";
import "../styles/HomeStyles.css";
import Banner from "../images/About.png";
import Story from "../images/Story.png";

const values = [
  "We drive the transition to sustainable transportation by providing a comprehensive one-stop solution.",
  "We design and deploy state-of-the-art clean energy EV charging solutions.",
  "Our AI-based software enhances efficiency and convenience.",
  "Our relentless innovation ensures sustainable EV chargers and intelligent software.",
  "We offer smart, customizable advanced scheduling and home charging solutions for all EVs and homes.",
  "We set new industry standards and enable a sustainable future, preserving the world for future generations."
];

const About = () => {
  return (

    <Layout>
      <div className="home" style={{
      backgroundImage: `url(${Banner})`,               
    }}>
           </div>
           <div className="home" style={{
      backgroundImage: `url(${Story})`,               
    }}>
           </div>
    <Box
      bgcolor="#22D23F36"
      p={8}
      borderRadius={3}
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection={{ xs: 'column', md: 'row' }}
      gap={8}
    >
      <Box
        flex={{ xs: 'auto', md: '1' }}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        textAlign="center"
      >
        <Typography variant="h2" fontWeight="bold" mb={4}>
          Our mission & values
        </Typography>
        <Typography variant="body1" paragraph>
          To redefine clean energy EV charging through ongoing innovation,
          expanding our positive impact globally.
        </Typography>
      </Box>

      <Box
        flex={{ xs: 'auto', md: '1' }}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        gap={2}
      >
        {values.map((value, index) => (
          <Box
            key={index}
            bgcolor="#FFFFFF99"
            p={2}
            borderRadius={2}
            boxShadow={3}
            width={{ xs: '100%', md: '90%' }} // Adjust width for different screen sizes
          >
            <Typography variant="body1" fontFamily='Poppins, sans-serif' paragraph>
              {value}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>

    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      p={8}
      bgcolor="#F3F4F6"
      borderRadius={3}
    >
      <Typography variant="h2" fontWeight="bold" mb={4}>
        Who we are
      </Typography>
      <Typography variant="h5" align="center" mb={2}>
        Our team is composed of passionate innovators, experts in
      </Typography>
      <Typography variant="h5" align="center" mb={2}>
       renewable energy, and advocates for sustainable
      </Typography>
      <Typography variant="h5" align="center" mb={8}>
      transportation.
    </Typography>
      <Box display="flex" gap={12} flexDirection={{ xs: 'column', md: 'row' }}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box
            width={242}
            height={242}
            bgcolor="gray"
            borderRadius={2}
            mb={4}
          />
          <Typography variant="h5" fontWeight="600">
            Saurav Kumar
          </Typography>
          <Typography variant="body2" align="center">
            Co-Founder & COO
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box
            width={242}
            height={242}
            bgcolor="gray"
            borderRadius={2}
            mb={4}
          />
          <Typography variant="h5" fontWeight="600">
            Jai Dev
          </Typography>
          <Typography variant="body2" align="center">
            Co-Founder & CMO
          </Typography>
        </Box>
      </Box>
    </Box>
    </Layout>
  )
}

export default About